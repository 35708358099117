import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";

const AddMeals = ({ CloseMealsSchedule }) => {
  const initialFormData = {
    user_ids: [],
    meal_ids: [],
    dates: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [meals, setMeals] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [userSearch, setUserSearch] = useState(""); // State for user search
  const [mealSearch, setMealSearch] = useState(""); // State for meal search
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchMeals();
    fetchUsers();
  }, []);

  const fetchMeals = async () => {
    try {
      const response = await api.get(
        "/admin/meals-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeals(response.data.data);
    } catch (error) {
      console.error("Error fetching meals:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get(
        "/admin/users-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleMealChange = (id) => {
    // Toggle selection of meal
    setFormValues((prevValues) => {
      const meal_ids = prevValues.meal_ids.includes(id)
        ? prevValues.meal_ids.filter((meal_id) => meal_id !== id)
        : [...prevValues.meal_ids, id];
      return { ...prevValues, meal_ids };
    });
  };

  const handleUserChange = (id) => {
    // Toggle selection of user
    setFormValues((prevValues) => {
      const user_ids = prevValues.user_ids.includes(id)
        ? prevValues.user_ids.filter((user_id) => user_id !== id)
        : [...prevValues.user_ids, id];
      return { ...prevValues, user_ids };
    });
  };

  const handleDateChange = (dates) => {
    const dateStrings = dates.map((date) => date.format("YYYY-MM-DD"));
    setSelectedDates(dates);
    setFormValues({
      ...formValues,
      dates: dateStrings,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure at least one user, one meal, and one date are selected
    if (formValues.user_ids.length === 0) {
      alert("Please select at least one user.");
      return;
    }
    if (formValues.meal_ids.length === 0) {
      alert("Please select at least one meal.");
      return;
    }
    if (formValues.dates.length === 0) {
      alert("Please select at least one date.");
      return;
    }

    const payload = {
      user_ids: formValues.user_ids,
      meal_ids: formValues.meal_ids,
      dates: formValues.dates,
    };

    try {
      setLoading(true);

      const response = await api.post("/admin/meal/bulk-add-users", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("Meals assigned successfully", response.data);
      CloseMealsSchedule();
    } catch (error) {
      console.error("Error assigning meals:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
    setLoading(false);
  };

  // Filter and sort users based on search query and selection status
  const filteredUsers = users
    .filter((user) =>
      user.name.toLowerCase().includes(userSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.user_ids.includes(a.id);
      const bSelected = formValues.user_ids.includes(b.id);
      return bSelected - aSelected;
    });

  // Filter and sort meals based on search query and selection status
  const filteredMeals = meals
    .filter((meal) =>
      meal.title.toLowerCase().includes(mealSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.meal_ids.includes(a.id);
      const bSelected = formValues.meal_ids.includes(b.id);
      return bSelected - aSelected;
    });

  // Separate selected and unselected users
  const selectedUsers = filteredUsers.filter((user) =>
    formValues.user_ids.includes(user.id)
  );
  const unselectedUsers = filteredUsers.filter(
    (user) => !formValues.user_ids.includes(user.id)
  );

  // Separate selected and unselected meals
  const selectedMeals = filteredMeals.filter((meal) =>
    formValues.meal_ids.includes(meal.id)
  );
  const unselectedMeals = filteredMeals.filter(
    (meal) => !formValues.meal_ids.includes(meal.id)
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="flex space-x-4">
            <div className="w-1/2 pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Users
              </h2>
              <input
                type="text"
                placeholder="Search Users"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {/* Unselected Users */}
                {unselectedUsers.map((user) => (
                  <label
                    key={user.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.user_ids.includes(user.id)}
                      onChange={() => handleUserChange(user.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{user.name}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Selected Users ({selectedUsers.length})
              </h2>
              <div className="max-h-40 overflow-y-auto space-y-2">
                {selectedUsers.map((user) => (
                  <label
                    key={user.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.user_ids.includes(user.id)}
                      onChange={() => handleUserChange(user.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{user.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="flex space-x-4 mt-6">
            <div className="w-1/2 pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Meals
              </h2>
              <input
                type="text"
                placeholder="Search Meals"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={mealSearch}
                onChange={(e) => setMealSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {/* Unselected Meals */}
                {unselectedMeals.map((meal) => (
                  <label
                    key={meal.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.meal_ids.includes(meal.id)}
                      onChange={() => handleMealChange(meal.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{meal.title}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Selected Meals ({selectedMeals.length})
              </h2>
              <div className="max-h-40 overflow-y-auto space-y-2">
                {selectedMeals.map((meal) => (
                  <label
                    key={meal.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.meal_ids.includes(meal.id)}
                      onChange={() => handleMealChange(meal.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{meal.title}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-sm space-y-4 mt-6">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Select Dates
              </h2>
              <DatePicker
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                required
              />
            </div>
          </div>
          <div className="flex justify-end mx-5 space-x-4 mt-6">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={CloseMealsSchedule}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMeals;
