import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppStore from "../assets/images/App Store.png";
import GooglePlay from "../assets/images/Google Play.png";

const PricingCard = ({
  name,
  price,
  old_price,
  features_detail,
  plan_type,
  description,
  invoice_period,
  syrianPrice,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();
  const perDay = parseFloat((price / (invoice_period * 30)).toFixed(2));
  const { t } = useTranslation();

  // Function to handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="relative  p-6 bg-[#1C1B1E] rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:border-orange-500 border-4 border-transparent w-11/12 md:w-96">
      <div className="absolute w-[calc(100%+8px)] rounded-t-lg h-14 -top-1 -left-1 bg-orange-500 text-white text-2xl font-bold pt-2">
        {plan_type === "Most Popular" ? t("mostPopular") : t("standard")}
      </div>

      <div className="text-center pt-3">
        <h3 className="text-white text-3xl font-semibold mt-12">{name}</h3>
        <h3 className="text-white text-base mt-2">{description}</h3>

        <p className="text-orange-400 text-xl mt-4">{features_detail}</p>

        <div className="flex items-start justify-center text-white mt-4 opacity-50">
          <sup className="text-sm">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-4xl line-through">{old_price}</span>
        </div>

        <div className="flex items-start justify-center text-white font-bold my-6 pb-2">
          <sup className="text-base">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-[55px] leading-[3rem]">{price}</span>
        </div>
        <p className="text-orange-400 text-xl">
          {syrianPrice ? "$" : "SYR"} {perDay} {t("perDay")}
        </p>
        <button
          className="bg-orange-500 font-semibold text-white w-3/4  text-[20px] py-3 mt-10 mb-2 rounded-3xl"
          onClick={() => setIsModalOpen(true)}
        >
          {t("subscribe")}
        </button>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
          {/* Modal Content */}
          <div
            className="bg-orange-500 p-6 w-11/12 rounded-lg shadow-lg space-y-10 flex-col mx-auto"
            ref={modalRef}
          >
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.raizer.orangefit"
                target="_blank"
                rel="noopener noreferrer"
                className="focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <img
                  src={GooglePlay}
                  className=" transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                  alt="Google Play"
                />
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/us/app/dr-michel-adib/id6605931077"
                target="_blank"
                rel="noopener noreferrer"
                className="focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <img
                  src={AppStore}
                  className=" transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                  alt="App Store"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
