import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import { FaPlusCircle } from "react-icons/fa";
import AddForm from "./AddForm";
import NotificationsWill from "./ScheduledNotifications";
import NotificationsDone from "./SentNotifications";
import Loader from "../../../components/Loader/Loader";

const Notifications = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [notifications, setNotifications] = useState([]);
  const [showNotificationsDone, setShowNotificationsDone] = useState(false); // New state for toggling views
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  const addNotification = async (notification) => {
    try {
      const response = await api.post("/admin/notifications", notification, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotifications([...notifications, response.data]);
      setShowAddForm(false);
      return response;
    } catch (error) {
      console.error("Error adding Notification:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [showNotificationsDone]); // Fetch notifications when toggling between Done and Will views

  useEffect(() => {
    if (update) {
      fetchNotifications();
      setUpdate(false);
    }
  }, [update]); // Fetch notifications when toggling between Done and Will views

  const fetchNotifications = async () => {
    setNotifications([]);
    setLoading(true);
    try {
      const url = showNotificationsDone
        ? "/admin/notifications?paginate=1000"
        : "/admin/scheduled-notifications";

      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching Notifications:", error);
    }
    setLoading(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const getSortedNotifications = (notifications) => {
    let sortedNotifications = [...notifications];

    if (sortOrder === "asc") {
      sortedNotifications.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOrder === "desc") {
      sortedNotifications.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOrder === "oldest") {
      sortedNotifications.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedNotifications.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedNotifications;
  };

  const filteredNotifications = getSortedNotifications(
    notifications.filter((notification) => {
      if (notification.title && typeof notification.title === "string") {
        return notification.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Notifications Dashboard
          </h1>
        </div>
      </div>

      {showAddForm && (
        <AddForm
          onSave={addNotification}
          onCancel={() => setShowAddForm(false)}
          setUpdate={setUpdate}
        />
      )}

      <div className="flex sm:flex-row flex-col sm:space-x-4 mt-8">
        <div className="sm:w-1/3 mb-4">
          <input
            type="text"
            id="search"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search by Title"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="sm:w-1/3 mb-4">
          <label htmlFor="sort" className="sr-only">
            Sort
          </label>
          <select
            id="sort"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={sortOrder}
            onChange={(e) => handleSortChange(e.target.value)}
          >
            <option value="asc">Title (A-Z)</option>
            <option value="desc">Title (Z-A)</option>
            <option value="oldest">Oldest</option>
            <option value="newest">Newest</option>
          </select>
        </div>
        <div className="mt-4 mb-6 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowAddForm(true)}
          >
            <FaPlusCircle className="mr-2" />
            Add Notifications
          </button>
        </div>
      </div>

      <div className="text-center">
        <button
          className={`text-2xl font-semibold mx-5 ${
            showNotificationsDone ? "text-green-600" : ""
          }`}
          onClick={() => setShowNotificationsDone(true)}
        >
          Sent Notifications
        </button>
        <button
          className={`text-2xl font-semibold mx-5 ${
            showNotificationsDone ? "" : "text-green-600"
          }`}
          onClick={() => setShowNotificationsDone(false)}
        >
          Scheduled Notifications
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : showNotificationsDone ? (
        <NotificationsDone
          filteredNotifications={filteredNotifications}
          setUpdate={setUpdate}
        />
      ) : (
        <NotificationsWill
          filteredNotifications={filteredNotifications}
          setUpdate={setUpdate}
        />
      )}
    </div>
  );
};

export default Notifications;
