import React, { useState, useEffect } from "react";
import api from "../../../../api/api";
import Loader from "../../../../components/Loader/Loader";
import Table from "../../../../components/Table/Table";
import EditPlans from "./EditPlans";

const PlanManagement = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authenticatedToken");
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);

    try {
      const response = await api.get("/admin/plans?paginate=100", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPlans(response.data.data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const headers = ["Plan Name", "	Price (USD)", "Price (Syria, S.P)"];

  const renderRow = (plan) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {plan.name || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {plan.price || ""} <span className="font-bold">$</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {plan.syr_price || ""} <span className="font-bold">S.P</span>
      </td>
    </>
  );

  const handleEditClick = (plan) => {
    setSelectedPlan(plan); // Set the selected plan to edit
    setShowEditForm(true); // Show the edit form
  };

  return (
    <div className=" p-8 border rounded shadow overflow-x-auto">
      <div className="sm:flex sm:items-center mb-10">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Plans Dashboard
          </h1>
        </div>
      </div>
      {showEditForm && (
        <EditPlans
          onCancel={() => setShowEditForm(false)}
          token={token}
          formData={selectedPlan}
          refresh={fetchPlans}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <Table
          headers={headers}
          data={plans}
          onEdit={handleEditClick}
          renderRow={renderRow}
        />
      )}
    </div>
  );
};

export default PlanManagement;
