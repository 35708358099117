import React, { useState, useEffect } from "react";
import api from "../../../api/api";

const AddForm = ({ onSave, onCancel }) => {
  const initialFormData = {
    id: null,
    name_en: "",
    name_ar: "",
    description_en: "",
    description_ar: "",
    workout_ids: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [workouts, setWorkouts] = useState([]);
  const [workoutSearch, setWorkoutSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const response = await api.get(
        "/admin/workouts-short-response?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts(response.data.data);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleWorkoutChange = (id) => {
    // Toggle selection of user
    setFormValues((prevValues) => {
      const workout_ids = prevValues.workout_ids.includes(id)
        ? prevValues.workout_ids.filter((workout_id) => workout_id !== id)
        : [...prevValues.workout_ids, id];
      return { ...prevValues, workout_ids };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedWorkouts.length == 0) {
      window.alert("you should selecte Workout");
    } else {
      try {
        setLoading(true);

        await onSave({
          ...formValues,
        });
        console.log(formValues);
        console.log("Routine updated successfully");
      } catch (error) {
        console.error("Error updating Routine:", error);
        alert(`Error: ${error.response ? error.response.data : error.message}`);
      }
      setLoading(false);
    }
  };

  // Filter and sort users based on search query and selection status
  const filteredWorkouts = workouts
    .filter((workout) =>
      workout.title.toLowerCase().includes(workoutSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.workout_ids.includes(a.id);
      const bSelected = formValues.workout_ids.includes(b.id);
      return bSelected - aSelected;
    });
  // Separate selected and unselected users
  const selectedWorkouts = filteredWorkouts.filter((workout) =>
    formValues.workout_ids.includes(workout.id)
  );
  const unselectedWorkouts = filteredWorkouts.filter(
    (workout) => !formValues.workout_ids.includes(workout.id)
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="name_en"
                  name="name_en"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.name_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="name_ar"
                  name="name_ar"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.name_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_en}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.description_ar}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2 pr-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Workouts
                </h2>
                <input
                  type="text"
                  placeholder="Search Workouts"
                  className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  defaultValue={workoutSearch}
                  onChange={(e) => setWorkoutSearch(e.target.value)}
                />
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {/* Unselected Users */}
                  {unselectedWorkouts.map((workout) => (
                    <label
                      key={workout.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.workout_ids.includes(workout.id)}
                        onChange={() => handleWorkoutChange(workout.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{workout.title}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="w-1/2 pl-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Selected Workouts ({selectedWorkouts.length})
                </h2>
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {selectedWorkouts.map((workout) => (
                    <label
                      key={workout.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.workout_ids.includes(workout.id)}
                        onChange={() => handleWorkoutChange(workout.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{workout.title}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mx-5 space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
