import React, { useEffect, useState } from "react";
import AppStore from "../../assets/images/App Store.png";
import GooglePlay from "../../assets/images/Google Play.png";
import levelUp1 from "../../assets/images/levelUp1.png";
import levelUp2 from "../../assets/images/levelUp2.png";
import { useTranslation } from "react-i18next";

const LevelUpSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [levelUp1, levelUp2];
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 0 ? 1 : 0));
    }, 1800);

    return () => clearInterval(intervalId);
  }, []);

  const textAlignment2 = i18n.language === "ar" ? "text-end" : "text-start";
  const textAlignment =
    i18n.language === "ar" ? "justify-end" : "justify-start";

  return (
    <section className="bg-[#151515] text-center text-white mb-32 md:mb-[550px]">
      <div className="flex flex-col md:flex-row space-y-20 justify-around">
        <div className="text-4xl md:text-[60px] relative top-20 md:top-44 z-10 font-semibold">
          <p
            className={`md:mb-4 text-center md:${textAlignment2} leading-normal`}
          >
            {t("levelUpTitle")}
          </p>
          <p className={`md:mb-10 text-center md:${textAlignment2}`}>
            {t("journeyWith")}
          </p>
          <p className={`md:mb-16 mb-6 text-center md:${textAlignment2}`}>
            {t("orangefitApp")}
          </p>
          <p className="mb-6 text-[#FF6400] leading-normal md:text-[25px] text-[20px] font-semibold">
            {t("appDescription")}
          </p>
          <div
            className={`hidden md:flex justify-center md:${textAlignment} space-x-4 py-4 z-10`}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.raizer.orangefit"
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={GooglePlay} className="w-60" alt="Google Play" />
            </a>
            <a
              href="https://apps.apple.com/us/app/dr-michel-adib/id6605931077"
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={AppStore} className="w-60" alt="App Store" />
            </a>
          </div>
        </div>

        <div className="relative z-10 md:right-40 lg:right-36 xl:right-28 2xl:right-28 w-[calc(100%-2rem)] pl-[1rem] md:w-[500px] pb-[100%] md:pb-0">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`levelUp${index + 1}`}
              className={`absolute md:left-1/4 transition-opacity duration-300 ${
                currentImage === index ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
        <div className="flex-col md:hidden mx-auto space-y-12">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.raizer.orangefit"
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img
                src={GooglePlay}
                className="w-4/6 mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                alt="Google Play"
              />
            </a>
          </div>
          <div>
            <a
              href="https://apps.apple.com/us/app/dr-michel-adib/id6605931077"
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img
                src={AppStore}
                className="w-4/6 mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                alt="App Store"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LevelUpSection;
