import React, { useState, useEffect } from "react";

const EditForm = ({ formData, onSave, onCancel, editing }) => {
  const initialFormData = {
    id: null,
    title_en: "",
    title_ar: "",
    instructions_en: "",
    instructions_ar: "",
    meal_type: "",
    nutrition_data: { calories: "", carbs: "", protein: "", fats: "" },
    tags: formData.tags ? [...formData.tags] : [],
    ingredients: formData.ingredients
      ? formData.ingredients.map((ingredient) => ({
          name: { en: ingredient.name.en || "", ar: ingredient.name.ar || "" },
          quantity: ingredient.quantity || "",
        }))
      : [],
    images: formData.images ? [...formData.images] : [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);

  useEffect(() => {
    if (formData) {
      setFormValues({
        id: formData.id || null,
        title_en: formData.title_en || "",
        title_ar: formData.title_ar || "",
        instructions_en: formData.instructions_en || "",
        instructions_ar: formData.instructions_ar || "",
        meal_type: formData.meal_type || "",
        nutrition_data: {
          calories: formData.nutrition_data?.calories || "",
          carbs: formData.nutrition_data?.carbs || "",
          protein: formData.nutrition_data?.protein || "",
          fats: formData.nutrition_data?.fats || "",
        },
        tags: formData.tags ? formData.tags.map((tag) => tag.name) : [],
        ingredients: formData.ingredients
          ? formData.ingredients.map((ingredient) => ({
              name: {
                en: ingredient.name.en || "",
                ar: ingredient.name.ar || "",
              },
              quantity: ingredient.quantity || "",
            }))
          : [],
        images: formData.images ? [...formData.images] : [],
      });

      const imagePreviews = (formData.photos || []).map((photo) => ({
        url: photo.url,
        preview: true,
      }));
      setPreviewImages(imagePreviews);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name in formValues.nutrition_data) {
      setFormValues({
        ...formValues,
        nutrition_data: {
          ...formValues.nutrition_data,
          [name]: value,
        },
      });
    } else if (name.startsWith("tag_")) {
      const index = parseInt(name.split("_")[1]);
      const newTags = [...formValues.tags];
      newTags[index] = value;
      setFormValues({ ...formValues, tags: newTags });
    } else if (name.startsWith("ingredient_")) {
      const [_, index, field, lang] = name.split("_");
      const newIngredients = [...formValues.ingredients];
      if (lang) {
        newIngredients[parseInt(index)].name[lang] = value;
      } else {
        newIngredients[parseInt(index)][field] = value;
      }
      setFormValues({ ...formValues, ingredients: newIngredients });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    setFormValues((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
  };

  const handleAddTag = () => {
    setFormValues({
      ...formValues,
      tags: [...formValues.tags, ""],
    });
  };

  const handleRemoveTag = (index) => {
    const newTags = formValues.tags.filter((_, i) => i !== index);
    setFormValues({ ...formValues, tags: newTags });
  };

  const handleAddIngredient = () => {
    setFormValues({
      ...formValues,
      ingredients: [
        ...formValues.ingredients,
        { name: { en: "", ar: "" }, quantity: "" },
      ],
    });
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...formValues.ingredients];
    newIngredients.splice(index, 1);
    setFormValues({ ...formValues, ingredients: newIngredients });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValues.ingredients.length === 0) {
      window.alert("You should add ingredients");
      return;
    }

    try {
      const validFormValues = {
        ...formValues,
        tags: formValues.tags.filter(
          (tag) => typeof tag === "string" && tag.trim() !== ""
        ),
        ingredients: formValues.ingredients.filter(
          (ingredient) =>
            ingredient.name.en.trim() !== "" && ingredient.name.ar.trim() !== ""
          // &&
          // ingredient.quantity.trim() !== ""
        ),
      };

      await onSave(validFormValues);

      // console.log("Meal updated successfully");
    } catch (error) {
      console.error("Error updating meal:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 space-y-6 max-h-[80vh] overflow-y-auto"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="title_en"
                  name="title_en"
                  type="text"
                  minLength="1"
                  maxlength="25"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="title_ar"
                  name="title_ar"
                  type="text"
                  minLength="1"
                  maxlength="25"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  defaultValue={formValues.title_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Meal Type
              </h2>
              <select
                id="meal_type"
                name="meal_type"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                defaultValue={formValues.meal_type}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Meal Type
                </option>
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
                <option value="Snack">Snack</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Calories
                </h2>
                <input
                  id="calories"
                  name="calories"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Calories"
                  defaultValue={formValues.nutrition_data.calories}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Carbs
                </h2>
                <input
                  id="carbs"
                  name="carbs"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Carbs"
                  defaultValue={formValues.nutrition_data.carbs}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Protein
                </h2>
                <input
                  id="protein"
                  name="protein"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Protein"
                  defaultValue={formValues.nutrition_data.protein}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Fats
                </h2>
                <input
                  id="fats"
                  name="fats"
                  type="number"
                  min="1"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Fats"
                  defaultValue={formValues.nutrition_data.fats}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Tags
              </h2>
              {formValues.tags.map((tag, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`tag_${index}`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Tag Name"
                    defaultValue={tag.name}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveTag(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>
            <div className="space-y-4 ">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Ingredients
              </h2>
              <div className="grid grid-cols-2 gap-6">
                {formValues.ingredients.map((ingredient, index) => (
                  <div key={index} className="flex-col  items-center space-x-2">
                    <h3 className="text-white text-start text-xl my-5 font-semibold">
                      Ingredient {index + 1} (English)
                    </h3>
                    <input
                      type="text"
                      name={`ingredient_${index}_name_en`}
                      className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                      placeholder="Ingredient Name (English)"
                      defaultValue={ingredient.name.en}
                      onChange={handleInputChange}
                    />{" "}
                    <h3 className="text-white text-start text-xl my-5 font-semibold mt-2">
                      Ingredient {index + 1} (Arabic)
                    </h3>
                    <input
                      type="text"
                      name={`ingredient_${index}_name_ar`}
                      className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                      placeholder="Ingredient Name (Arabic)"
                      defaultValue={ingredient.name.ar}
                      onChange={handleInputChange}
                    />{" "}
                    <h3 className="text-white text-start text-xl my-5 font-semibold">
                      Quantity
                    </h3>
                    <input
                      type="text"
                      name={`ingredient_${index}_quantity`}
                      className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                      placeholder="Quantity"
                      defaultValue={ingredient.quantity}
                      onChange={handleInputChange}
                    />
                    <button
                      type="button"
                      className="bg-red-500 text-white px-4 py-2  my-4 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                      onClick={() => handleRemoveIngredient(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddIngredient}
              >
                Add Ingredient
              </button>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Instructions
              </h2>
              <textarea
                id="instructions_en"
                name="instructions_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.instructions_en.replace(/--/g, "\n")}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Instructions
              </h2>
              <textarea
                id="instructions_ar"
                name="instructions_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.instructions_ar.replace(/--/g, "\n")}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Photos
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end mx-5 space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={editing}
            >
              {editing ? "Loading..." : "Edit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
