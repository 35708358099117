import React from "react";

import man1 from "../../assets/images/Frame (1).png";
import man2 from "../../assets/images/Frame (2).png";
import man3 from "../../assets/images/Frame (3).png";
import man4 from "../../assets/images/Frame (4).png";
import man5 from "../../assets/images/Frame (5).png";
import man6 from "../../assets/images/Frame (6).png";
import man7 from "../../assets/images/Frame (7).png";
import man8 from "../../assets/images/Frame (8).png";
import circles from "../../assets/images/circles.png";
import Feedback from "../../assets/images/Feedback.png";
import Feedback2 from "../../assets/images/Feedback (1).png";
import Feedback3 from "../../assets/images/Feedback (2).png";
import Feedback4 from "../../assets/images/Feedback (3).png";
import Feedback5 from "../../assets/images/Feedback (4).png";
import Feedback6 from "../../assets/images/Feedback (5).png";
import Feedback7 from "../../assets/images/Feedback (6).png";
import Feedback8 from "../../assets/images/Feedback (7).png";
import Feedback9 from "../../assets/images/Feedback (8).png";
import Feedback10 from "../../assets/images/Feedback (9).png";
import Feedback11 from "../../assets/images/Feedback (10).png";
import Feedback12 from "../../assets/images/Feedback (11).png";
import Feedback13 from "../../assets/images/Feedback (12).png";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Slider from "react-slick";
import "./TextSlider.css";
import { useTranslation } from "react-i18next";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute -bottom-12 md:-bottom-20 right-1/4 transform  z-10 cursor-pointer"
    onClick={onClick}
  >
    <span className="text-4xl md:text-6xl text-white hover:scale-110 transition-transform">
      <FaArrowAltCircleRight />
    </span>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute -bottom-12 md:-bottom-20 left-1/4 transform z-10 cursor-pointer"
    onClick={onClick}
  >
    <span className="text-4xl md:text-6xl text-white hover:scale-110 transition-transform">
      <FaArrowAltCircleLeft />
    </span>
  </div>
);

const TestimonialsSection = () => {
  const { t } = useTranslation();

  const images = [
    {
      url: man1,
    },
    {
      url: man2,
    },
    {
      url: man3,
    },
    {
      url: man4,
    },
    {
      url: man5,
    },
    {
      url: man6,
    },
    {
      url: man7,
    },
    {
      url: man8,
    },
  ];

  const images2 = [
    {
      url: Feedback,
    },
    {
      url: Feedback2,
    },
    {
      url: Feedback3,
    },
    {
      url: Feedback4,
    },
    {
      url: Feedback5,
    },
    {
      url: Feedback6,
    },
    {
      url: Feedback7,
    },
    {
      url: Feedback8,
    },
    {
      url: Feedback9,
    },
    {
      url: Feedback10,
    },
    {
      url: Feedback11,
    },
    {
      url: Feedback12,
    },
    {
      url: Feedback13,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className="bg-gradient-to-br from-[#FF6400] to-[#FE8E22] text-white pt-20 pb-40 rounded-3xl">
      <div className="mx-auto px-4 flex flex-col  text-center md:flex-row md:justify-around">
        <h2 className="text-4xl md:text-6xl text-white font-bold pt-10 md:pt-36">
          <span
            className="leading-snug"
            dangerouslySetInnerHTML={{ __html: t("orangefitBest") }}
          />
        </h2>
        <div className="w-11/12 md:w-3/5 mx-auto mt-16 md:mt-0">
          <div className="text-center w-full space-x-[30%] pb-4 md:pb-8">
            <p className="inline-block  text-3xl md:text-4xl font-semibold">
              {t("before")}
            </p>
            <p className="inline-block  text-3xl md:text-4xl font-semibold">
              {t("after")}
            </p>
          </div>
          <Slider {...settings} className="  w-full ">
            {images.map((image, index) => (
              <div key={index} className="px-3">
                <img src={image.url} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="text-center mt-40 md:mt-52 relative z-10">
        <p className="text-5xl font-semibold mb-20">{t("clientTestimonail")}</p>
        <Slider {...settings} className="w-10/12  md:w-1/3 mx-auto">
          {images2.map((image, index) => (
            <div key={index} className="px-3">
              <img src={image.url} />
            </div>
          ))}
        </Slider>

        <img
          src={circles}
          className="w-full md:w-2/5 -z-10 absolute top-20  md:right-1/3 md:pl-20"
          alt="Circles Background"
        />
      </div>
    </section>
  );
};

export default TestimonialsSection;
