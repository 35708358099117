import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"; // Optional: Import a theme

const AddForm = ({ onSave, onCancel, setUpdate }) => {
  const initialFormData = {
    title: "",
    body: "",
    link: "",
    user_ids: [],
    scheduled_time: null, // Initialize scheduled_time as null
  };

  // Set initial date to tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [formValues, setFormValues] = useState(initialFormData);
  const [users, setUsers] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState(tomorrow); // Initialize to tomorrow
  const [selectedTime, setSelectedTime] = useState(""); // To hold time value
  const [scheduleEnabled, setScheduleEnabled] = useState(false); // Control checkbox

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (scheduleEnabled && selectedDate) {
      const date = selectedDate.toISOString().split("T")[0];
      const time = selectedTime || "00:00:00"; // Default to midnight if no time is selected
      setFormValues((prevValues) => ({
        ...prevValues,
        scheduled_time: `${date}T${time}`,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        scheduled_time: null,
      }));
    }
  }, [scheduleEnabled, selectedDate, selectedTime]);

  const fetchUsers = async () => {
    try {
      const response = await api.get("/admin/users-short-response", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUserChange = (id) => {
    setFormValues((prevValues) => {
      const user_ids = prevValues.user_ids.includes(id)
        ? prevValues.user_ids.filter((user_id) => user_id !== id)
        : [...prevValues.user_ids, id];
      return { ...prevValues, user_ids };
    });
  };

  const handleDateChange = (date) => {
    const newDate = date ? date.toDate() : null;
    setSelectedDate(newDate);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleCheckboxChange = () => {
    setScheduleEnabled(!scheduleEnabled);
  };

  // Filter and sort users based on search query and selection status
  const filteredUsers = users
    .filter((user) =>
      user.name.toLowerCase().includes(userSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.user_ids.includes(a.id);
      const bSelected = formValues.user_ids.includes(b.id);
      return bSelected - aSelected;
    });

  // Separate selected and unselected users
  const selectedUsers = filteredUsers.filter((user) =>
    formValues.user_ids.includes(user.id)
  );
  const unselectedUsers = filteredUsers.filter(
    (user) => !formValues.user_ids.includes(user.id)
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedUsers.length == 0) {
      window.alert("you should selecte Users");
    } else {
      try {
        setLoading(true);

        await onSave({
          title: formValues.title,
          user_ids: formValues.user_ids,
          body: formValues.body,
          link: formValues.link,
          scheduled_time: formValues.scheduled_time,
        });
        // console.log("Notification saved successfully");
        setUpdate(true);
      } catch (error) {
        console.error("Error saving notification:", error);
        alert(`Error: ${error.response ? error.response.data : error.message}`);
      }
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4 pr-5">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Title
              </h2>
              <input
                id="title"
                name="title"
                type="text"
                minLength="1"
                maxLength="25"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Title"
                defaultValue={formValues.title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Link
              </h2>
              <input
                id="link"
                name="link"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Link"
                defaultValue={formValues.link}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Description
              </h2>
              <textarea
                id="body"
                name="body"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                defaultValue={formValues.body}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex space-x-4">
              <div className="w-1/2 pr-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Users
                </h2>
                <input
                  type="text"
                  placeholder="Search Users"
                  className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  defaultValue={userSearch}
                  onChange={(e) => setUserSearch(e.target.value)}
                />
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {/* Unselected Users */}
                  {unselectedUsers.map((user) => (
                    <label
                      key={user.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.user_ids.includes(user.id)}
                        onChange={() => handleUserChange(user.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{user.name}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="w-1/2 pl-2">
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Selected Users ({selectedUsers.length})
                </h2>
                <div className="max-h-40 overflow-y-auto space-y-2">
                  {selectedUsers.map((user) => (
                    <label
                      key={user.id}
                      className="flex items-center text-white space-x-3"
                    >
                      <input
                        type="checkbox"
                        checked={formValues.user_ids.includes(user.id)}
                        onChange={() => handleUserChange(user.id)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>{user.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded-md shadow-sm space-y-4 mt-6">
              <div>
                <label className="flex items-center text-white">
                  <input
                    type="checkbox"
                    checked={scheduleEnabled}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                  />
                  <span class="ml-2">Schedule Notification</span>
                </label>
              </div>

              {scheduleEnabled && (
                <>
                  <div>
                    <h2 className="text-white text-start text-2xl mb-2 font-bold">
                      Select Date
                    </h2>
                    <DatePicker
                      value={selectedDate}
                      onChange={handleDateChange}
                      minDate={new Date()} // Prevent selecting past dates
                      onlyDatePicker
                      format="YYYY-MM-DD"
                    />
                  </div>

                  <div>
                    <h2 className="text-white text-start text-2xl mb-2 font-bold">
                      Select Time
                    </h2>
                    <input
                      type="time"
                      defaultValue={selectedTime}
                      onChange={handleTimeChange}
                      className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end mx-5 space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
