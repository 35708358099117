import React, { useState, useEffect } from "react";
import FAQItem from "../FAQItem";
import { useTranslation } from "react-i18next";
import telegram from "../../assets/images/telegram.png";

const FAQSection = () => {
  const { t, i18n } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const isArabic = i18n.language === "ar";
  const direction = isArabic ? "rtl" : "ltr";

  // Set showMore to true on mobile devices
  useEffect(() => {
    if (window.innerWidth >= 728) {
      setShowMore(true);
    }
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <section className="bg-[#151515] text-white h-full flex flex-col items-center py-10">
      <div
        className="flex flex-col-reverse md:flex-row justify-evenly w-full px-6"
        dir={direction}
      >
        {/* Left Section: FAQ */}
        {showMore && (
          <div className="w-full md:w-2/5 space-y-8">
            <FAQItem
              question={t("questions.q2")}
              answer={t("answers.a2")}
              isOpen={openIndex === 1}
              onClick={() => handleToggle(1)}
            />
            <FAQItem
              question={t("questions.q7")}
              answer={t("answers.a7")}
              isOpen={openIndex === 7}
              onClick={() => handleToggle(7)}
            />
            <FAQItem
              question={t("questions.q9")}
              answer={t("answers.a9")}
              isOpen={openIndex === 4}
              onClick={() => handleToggle(4)}
            />
            <FAQItem
              question={t("questions.q10")}
              answer={t("answers.a10")}
              isOpen={openIndex === 10}
              onClick={() => handleToggle(10)}
            />{" "}
            <FAQItem
              question={t("questions.q8")}
              answer={t("answers.a8")}
              isOpen={openIndex === 8}
              onClick={() => handleToggle(8)}
            />
          </div>
        )}
        <div className="w-full md:w-2/5 space-y-8 mb-8">
          <FAQItem
            question={t("questions.q1")}
            answer={t("answers.a1")}
            isOpen={openIndex === 0}
            onClick={() => handleToggle(0)}
          />

          <FAQItem
            question={t("questions.q3")}
            answer={t("answers.a3")}
            isOpen={openIndex === 2}
            onClick={() => handleToggle(2)}
          />
          <FAQItem
            question={t("questions.q4")}
            answer={t("answers.a4")}
            isOpen={openIndex === 3}
            onClick={() => handleToggle(3)}
          />
          {showMore && (
            <>
              <FAQItem
                question={t("questions.q5")}
                answer={t("answers.a5")}
                isOpen={openIndex === 5}
                onClick={() => handleToggle(5)}
              />
              <FAQItem
                question={t("questions.q11")}
                answer={t("answers.a11")}
                isOpen={openIndex === 11}
                onClick={() => handleToggle(11)}
              />
              <FAQItem
                question={t("questions.q6")}
                answer={t("answers.a6")}
                isOpen={openIndex === 6}
                onClick={() => handleToggle(6)}
              />
            </>
          )}
        </div>
      </div>
      <button
        onClick={handleShowMore}
        className="text-orange-500 mt-8 text-center block md:hidden"
      >
        {showMore ? "Show Less" : "More FAQ"} ↓
      </button>
      <div className="text-center mt-16">
        <p className="text-white text-5xl font-semibold mb-5">{t("help")}</p>
        <p className="text-white opacity-75 text-2xl mb-10 font-semibold">
          {t("contact")}
        </p>
        <a
          href="https://wa.me/00971501346105"
          target="_blank"
          rel="noopener noreferrer"
          className="transform transition-transform duration-200 hover:scale-110 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
          <button className="bg-[#FD7B03] text-white py-3 px-6 rounded-full cursor-pointer transform transition-transform duration-200 hover:scale-105 hover:shadow-lg">
            {isArabic && (
              <img className="w-5 inline mr-2" src={telegram} alt="Telegram" />
            )}
            {t("sendBtn")}
            {!isArabic && (
              <img className="w-5 inline ml-2" src={telegram} alt="Telegram" />
            )}
          </button>
        </a>
      </div>
    </section>
  );
};

export default FAQSection;
