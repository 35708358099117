import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import AddRoutines from "./AddRoutines";
import AddMeals from "./AddMeals";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination";
import Table from "../../../components/Table/Table";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showRoutinesSchedule, setShowRoutinesSchedule] = useState(false);
  const [showMealsSchedule, setShowMealsSchedule] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Number of users per page
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/users?paginate=1000", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  const deleteUser = async (id) => {
    try {
      await api.delete(`/admin/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1); // Reset to first page on sort
  };

  const getSortedUsers = (users) => {
    let sortedUsers = [...users];

    if (sortOrder === "asc") {
      sortedUsers.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === "desc") {
      sortedUsers.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sortOrder === "oldest") {
      sortedUsers.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedUsers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedUsers;
  };

  const filteredUsers = getSortedUsers(
    users.filter((user) =>
      user.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Calculate pagination
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const headers = [
    "Name ",
    "Email",
    "Phone",
    "Sex",
    "Weight (Kg)",
    "Height (cm)",
    "Birth Date",
  ];

  const renderRow = (user) => (
    <>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.name || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 overflow-hidden"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.email || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.phone || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.sex || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.weight || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.height || ""}
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        onClick={() => navigate(`${user.id}`)}
      >
        {user.birth_date || ""}
      </td>
    </>
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-semibold text-gray-900">Users Dashboard</h1>

      {showRoutinesSchedule && (
        <AddRoutines
          CloseRoutinesSchedule={() => setShowRoutinesSchedule(false)}
        />
      )}
      {showMealsSchedule && (
        <AddMeals CloseMealsSchedule={() => setShowMealsSchedule(false)} />
      )}

      <div className="mt-8 mb-6 flex flex-col md:space-x-6 md:flex-row">
        <div className="flex space-x-8 md:w-2/3 w-auto">
          <div className="sm:w-2/4 mb-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              id="search"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by Name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="sm:w-2/4 mb-4">
            <label htmlFor="sort" className="sr-only">
              Sort
            </label>
            <select
              id="sort"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="asc">Name (A-Z)</option>
              <option value="desc">Name (Z-A)</option>
              <option value="oldest">Oldest</option>
              <option value="newest">Newest</option>
            </select>
          </div>
        </div>

        <div className="flex space-x-8 flex-row justify-around">
          <button
            type="button"
            className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowRoutinesSchedule(true)}
          >
            <FaPlusCircle className="mr-2" />
            Routines Schedule
          </button>
          <button
            type="button"
            className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowMealsSchedule(true)}
          >
            <FaPlusCircle className="mr-2" />
            Meals Schedule
          </button>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <Table
          headers={headers}
          data={currentUsers}
          onDelete={deleteUser}
          renderRow={renderRow}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Users;
