import React from "react";

const UserDetails = ({ user, formatDate }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 ml-8 w-full">
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Sex:
        </label>
        <input
          value={user?.sex || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Phone:
        </label>
        <input
          value={user?.phone || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Instagram Account:
        </label>
        <input
          value={user?.instagram_account || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Birth Date:
        </label>
        <input
          value={user?.birth_date || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Country:
        </label>
        <input
          value={user?.country || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          City:
        </label>
        <input
          value={user?.city || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Location:
        </label>
        <input
          value={user?.location || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Goal:
        </label>
        <input
          value={user?.goal || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Weight:
        </label>
        <input
          value={user?.weight || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Height:
        </label>
        <input
          value={user?.height || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Previous Diet Status:
        </label>
        <input
          value={user?.previous_diet_status || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Has Allergies:
        </label>
        <input
          value={user?.has_allergies || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Allergies Details:
        </label>
        <input
          value={user?.allergies_details || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Smoking Status:
        </label>
        <input
          value={user?.smoking_status || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Alcohol Status:
        </label>
        <input
          value={user?.alcohol_status || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Sports Practiced:
        </label>
        <input
          value={user?.sports_practiced || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Uses Protein Supplement:
        </label>
        <input
          value={user?.uses_protein_supplement || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Unwanted Carbohydrate:
        </label>
        <input
          value={user?.unwanted_carbohydrate || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Unwanted Protein:
        </label>
        <input
          value={user?.unwanted_protein || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Unwanted Fats:
        </label>
        <input
          value={user?.unwanted_fats || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Registration Complete:
        </label>
        <input
          value={user?.registration_complete || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Onboarding Current Step:
        </label>
        <input
          value={user?.onboarding_current_step || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Has Active Subscription:
        </label>
        <input
          value={user?.has_active_subscription ? "yes" : "no"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Requested Plan:
        </label>
        <input
          value={user?.plan_subscriptions?.name || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Current Routine:
        </label>
        <input
          value={user?.current_routine || "N/A"}
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Starts At:
        </label>
        <input
          value={
            user?.plan_subscriptions?.starts_at
              ? formatDate(user?.plan_subscriptions?.starts_at)
              : "N/A"
          }
          disabled
          className="input-field w-full"
        />
      </div>
      <div>
        <label className="block text-sm font-semibold text-gray-600 mb-1">
          Ends At:
        </label>
        <input
          value={
            user?.plan_subscriptions?.ends_at
              ? formatDate(user?.plan_subscriptions?.ends_at)
              : "N/A"
          }
          disabled
          className="input-field w-full"
        />
      </div>
    </div>
  );
};

export default UserDetails;
