import React, { useEffect, useState } from "react";
import PricingCard from "../PricingCard";
import { useTranslation } from "react-i18next";
import api from "../../api/api";

const PricingSection = () => {
  const [plansPrice, setPlansPrice] = useState([]);
  const [showSyrianPrice, setShowSyrianPrice] = useState(false);
  const { t, i18n } = useTranslation();
  // const textAlignment2 = i18n.language === "ar" ? "text-end" : "text-center";
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchPlans();
  }, [i18n.language]);

  const fetchPlans = async () => {
    try {
      const language = i18n.language === "ar" ? "ar" : "en"; // Determine the language
      const response = await api.get(`/admin/plans?paginate=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": language, // Set the language header
        },
      });
      setPlansPrice(response.data.data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  return (
    <>
      <section className="bg-[#151515] text-white pt-20">
        <div className="hidden md:flex relative bg-[#1a1a1a] h-0 md:-bottom-48 -bottom-[300px]">
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFAA4C"
              fillOpacity="1"
              d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,192C840,203,960,213,1080,208C1200,203,1320,181,1380,176L1440,171L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#151515"
              fillOpacity="1"
              d="M0,288L60,272C120,256,240,224,360,224C480,224,600,256,720,266.7C840,277,960,267,1080,245.3C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="max-w-6xl mx-auto px-4">
          <h2 className={"text-5xl font-bold mb-20 text-center"}>
            {t("choosePlan")}
          </h2>

          <div
            className={`flex flex-col md:flex-row justify-center items-center space-y-12 md:space-y-0 md:space-x-40 xl:space-x-64 text-center`}
          >
            {plansPrice.length > 0 ? (
              <>
                <PricingCard
                  name={plansPrice[0]?.name}
                  price={
                    showSyrianPrice
                      ? plansPrice[0]?.price
                      : plansPrice[0]?.syr_price
                  }
                  features={t("dietOnly")}
                  Subscribe={t("Subscribe")}
                  plan_type={plansPrice[0]?.plan_type}
                  description={plansPrice[0]?.description}
                  features_detail={plansPrice[0]?.features_detail}
                  old_price={
                    showSyrianPrice
                      ? plansPrice[0]?.old_price
                      : plansPrice[0]?.old_syr_price
                  }
                  invoice_period={plansPrice[0]?.invoice_period}
                  syrianPrice={showSyrianPrice}
                />
                <PricingCard
                  name={plansPrice[1]?.name}
                  price={
                    showSyrianPrice
                      ? plansPrice[1]?.price
                      : plansPrice[1]?.syr_price
                  }
                  features={t("dietAndWorkout")}
                  plan_type={plansPrice[1]?.plan_type}
                  description={plansPrice[1]?.description}
                  features_detail={plansPrice[1]?.features_detail}
                  old_price={
                    showSyrianPrice
                      ? plansPrice[1]?.old_price
                      : plansPrice[1]?.old_syr_price
                  }
                  invoice_period={plansPrice[1]?.invoice_period}
                  syrianPrice={showSyrianPrice}
                />
              </>
            ) : (
              <p>{t("loading")}</p>
            )}
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setShowSyrianPrice(!showSyrianPrice)}
              className="bg-orange-500 font-semibold text-white text-[20px] py-3 px-6 mt-10 rounded-3xl"
            >
              {showSyrianPrice ? t("syPrice") : t("usdPrice")}
            </button>
          </div>
        </div>
      </section>

      <div className="w-9/12 mx-auto mt-20 mb-10 h-1 bg-gradient-to-r from-transparent via-orange-500 to-transparent"></div>
    </>
  );
};

export default PricingSection;
